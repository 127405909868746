import { useCallback } from 'react';
import { useRouter } from 'next/router';

// custom hook used to combat rehydration issues
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export const useRedirectToGetStartedPage = (): {
    redirectToGetStartedPage: () => void;
} => {
    const router = useRouter();
    const { asPath } = router;

    const redirectToGetStartedPage = useCallback(() => {
        const getStartedUrl = `/get_started${asPath ? `?redirect=${encodeURIComponent(asPath)}` : ''}`;
        router.push(getStartedUrl);
    }, [asPath, router]);

    return {
        redirectToGetStartedPage
    };
};
