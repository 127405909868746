import { UserFavoriteListing } from '@bladebinge/types';
import { PROXY_ROUTES } from '../ui-proxy-routes';
import { normalizedFetch } from '../utils/normalized-fetch';

export const addUserFavoriteListingsRequest = async ({
    listingIds,
    userId
}: {
    listingIds: string[];
    userId: string;
}): Promise<UserFavoriteListing[]> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.addUserFavoriteListings.build({
            userId
        }),
        options: {
            method: 'POST',
            body: JSON.stringify({
                listingIds
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};

export const deleteUserFavoriteListingsRequest = async ({
    listingIds,
    userId
}: {
    listingIds: string[];
    userId: string;
}): Promise<UserFavoriteListing[]> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.removeUserFavoriteListings.build({
            userId
        }),
        options: {
            method: 'POST',
            body: JSON.stringify({
                listingIds
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
