import React, { SyntheticEvent, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ListingGraph, UserFavoriteListing } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useMe } from '../../context/me/me-context';
import { useUserFavoriteListingIds } from '../../hooks/react-query/logged-in-user-hooks/use-user-favorite-listing-ids';
import { useRedirectToGetStartedPage } from '../../hooks/use-redirect-to-get-started-page';
import {
    addUserFavoriteListingsRequest,
    deleteUserFavoriteListingsRequest
} from '../../server/api-proxy/ui-mutation-fns/user-favorite-listings';
import { useUserPreferences } from '../../context/user-preferences/user-preferences-context';

export const FavoriteIconButton = ({
    className = '',
    listing,
    size = 'small'
}: {
    readonly className?: string;
    readonly listing: ListingGraph;
    readonly size?: 'small' | 'medium' | 'large';
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { id: userId } = useMe();
    const { redirectToGetStartedPage } = useRedirectToGetStartedPage();
    const { persistedUserPreferences } = useUserPreferences();

    const includeSoldQuery = useMemo(
        () => ({
            includeSold: `${persistedUserPreferences?.favorites?.showSoldOutItems ?? true}`
        }),
        [persistedUserPreferences]
    );
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const { data: userFavoriteIdsData = [], error: userFavoriteListingsError } = useUserFavoriteListingIds({
        userId
    });

    const isFavorited = useMemo(
        () => Boolean(userFavoriteIdsData.some(({ listingId }) => listingId === listing?.id)),
        [listing, userFavoriteIdsData]
    );

    const addFavoriteMutation = useMutation({
        mutationFn: addUserFavoriteListingsRequest,
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.loggedInUserFavoriteListings({
                    loggedInUserId: userId,
                    query: includeSoldQuery
                })
            });
            queryClient.setQueryData(
                uiCacheKeyBuilderMap.loggedInUserFavoriteListingIds({
                    loggedInUserId: userId
                }),
                (results: UserFavoriteListing[]) => [
                    {
                        createdAt: new Date().toISOString(),
                        listingId: listing?.id,
                        userId
                    },
                    ...results
                ]
            );
        }
    });

    const deleteFavoriteListingsMutation = useMutation({
        mutationFn: deleteUserFavoriteListingsRequest,
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.loggedInUserFavoriteListings({
                    loggedInUserId: userId,
                    query: includeSoldQuery
                })
            });
            queryClient.setQueryData(
                uiCacheKeyBuilderMap.loggedInUserFavoriteListingIds({
                    loggedInUserId: userId
                }),
                (results: UserFavoriteListing[]) => (results ?? []).filter(({ listingId }) => listingId !== listing?.id)
            );
        }
    });
    const {
        isPending: isDeletingFavorite,
        error: deleteFavoriteListingsError,
        reset: resetDeleteFavoriteListingsMutation
    } = deleteFavoriteListingsMutation;

    const favoriteRemovalHandler = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (!userId || !isFavorited) {
            return;
        }

        deleteFavoriteListingsMutation.mutate({
            userId,
            listingIds: [listing?.id]
        });

        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setSnackbarOpen(false);
    };

    const handleFavoriteClick = (e: SyntheticEvent) => {
        e.stopPropagation();

        if (!userId) {
            redirectToGetStartedPage();
            return;
        }

        if (isFavorited) {
            return;
        }

        addFavoriteMutation.mutate({
            userId,
            listingIds: [listing?.id]
        });

        setSnackbarOpen(true);
    };

    const submitButtonLabel = useMemo(
        () =>
            isFavorited
                ? t('common:user_favorites.remove_from_favorites')
                : t('common:user_favorites.add_to_favorites'),
        [isFavorited, t]
    );

    const snackbarMessage = useMemo(
        () =>
            isFavorited
                ? t('common:user_favorites.item_added_to_favorites')
                : t('common:user_favorites.item_removed_from_favorites'),
        [isFavorited, t]
    );

    if (!listing?.id) {
        return null;
    }

    // no need to favorite your own items
    const isListingOwnedByLoggedInUser = userId === listing?.ownerId;
    const interactionClass = isListingOwnedByLoggedInUser ? '' : isFavorited ? 'unfavorite-btn' : 'favorite-btn';

    const submitHandler = isFavorited ? favoriteRemovalHandler : handleFavoriteClick;

    const deletionErrorCloseHandler = (e: SyntheticEvent) => {
        e.stopPropagation();
        resetDeleteFavoriteListingsMutation();
    };

    return (
        <>
            <IconButton
                className={`${className} ${interactionClass}`}
                disabled={isDeletingFavorite || isListingOwnedByLoggedInUser}
                aria-label={submitButtonLabel}
                onClick={submitHandler}
                title={submitButtonLabel}
                size={size}
                sx={{
                    ...(isListingOwnedByLoggedInUser
                        ? {
                              opacity: 0.75
                          }
                        : {
                              '&:hover': {
                                  backgroundColor: 'divider'
                              }
                          })
                }}
            >
                <>
                    {userFavoriteListingsError && (
                        <Alert severity="error">
                            <Typography variant="body2" component="div">
                                {userFavoriteListingsError?.message}
                            </Typography>
                        </Alert>
                    )}
                    {deleteFavoriteListingsError && (
                        <Alert severity="error" onClose={deletionErrorCloseHandler}>
                            <Typography variant="body2" component="div">
                                {deleteFavoriteListingsError?.message}
                            </Typography>
                        </Alert>
                    )}
                    {isFavorited ? (
                        <FavoriteIcon
                            sx={{ fontSize: 'inherit' }}
                            color={isListingOwnedByLoggedInUser ? 'inherit' : 'primary'}
                        />
                    ) : (
                        <FavoriteBorderIcon
                            sx={{ fontSize: 'inherit' }}
                            color={isListingOwnedByLoggedInUser ? 'inherit' : 'primary'}
                        />
                    )}
                </>
            </IconButton>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                color="secondary"
                ContentProps={{
                    sx: {
                        backgroundColor: 'secondary.light',
                        color: 'text.contrastText'
                    }
                }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};
