import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const ItemQuantitySelector = ({
    className,
    disabled = false,
    defaultSelection,
    fullWidth = false,
    itemId,
    max,
    onSelect,
    selectControlLabel,
    zeroLabel
}: {
    readonly className?: string;
    readonly disabled?: boolean;
    readonly defaultSelection: number;
    readonly fullWidth?: boolean;
    readonly itemId: string;
    readonly max: number;
    readonly onSelect: ((qty: number) => void) | ((qty: number) => Promise<void>);
    readonly selectControlLabel?: string;
    readonly zeroLabel?: string;
}) => {
    const { t } = useTranslation();
    const [selectedQuantity, setSelectedQuantity] = useState<number | null>(defaultSelection);

    const handleSelectQuantity = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const qtySelectionInputVal = event.target.value;
        const qtySelection = qtySelectionInputVal ? parseInt(qtySelectionInputVal as string, 10) : undefined;

        if (qtySelection === 0 || qtySelection) {
            setSelectedQuantity(qtySelection);
            onSelect(qtySelection);
        }
    };

    // eslint-disable-next-line unicorn/no-new-array
    const selectableRangeArray = Array.from(new Array(max + 1).keys());
    const labelId = `item_qty_${itemId}`;
    const controlLabel = selectControlLabel ?? t('common:general.quantity');

    return (
        <FormControl size="small" fullWidth={fullWidth} sx={{ mt: 1, mb: 0 }}>
            <TextField
                disabled={disabled}
                select
                id={labelId}
                label={controlLabel}
                slotProps={{
                    inputLabel: {
                        shrink: true
                    }
                }}
                onChange={handleSelectQuantity}
                value={selectedQuantity}
                size="small"
                variant="outlined"
            >
                {selectableRangeArray.map((quantityChoice, index) => {
                    if (index === 0) {
                        return zeroLabel ? (
                            <MenuItem key="qty_choice_0" value="0">
                                <Typography variant="body2">{zeroLabel ?? 0}</Typography>
                            </MenuItem>
                        ) : null;
                    }

                    return (
                        <MenuItem key={`qty_choice_${quantityChoice}`} value={quantityChoice}>
                            <Typography variant="body2">{quantityChoice}</Typography>
                        </MenuItem>
                    );
                })}
            </TextField>
        </FormControl>
    );
};
